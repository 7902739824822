import "./App.sass";

function App() {
  return (
    <div className="app_toplevel">
      <div className="app">
        {" "}
        <div className="app_text">HELLO</div>
      </div>
    </div>
  );
}

export default App;
